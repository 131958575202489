import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { handleConfigsFetch, handleConfigVersionFetch, serverDateTimeDifferenceFetchError, serverDateTimeDifferenceFetchSuccess } from '@/store/Settings';
import {
    Navigate,
    Routes,
    Route,
} from "react-router-dom";
import { AuthRoute } from './AuthRoute';

import Home from '@/pages/Home/Home';
import RegisterConfirmation from '@/pages/RegisterConfirmation/RegisterConfirmation';
import ConfirmAccount from '@/pages/ConfirmAccount/ConfirmAccount';
import ConfirmAccountConfirmation from '@/pages/ConfirmAccountConfirmation/ConfirmAccountConfirmation';
import EmailChangeConfirmation from '@/pages/EmailChangeConfirmation/EmailChangeConfirmation';
import ForgotPassword from '@/pages/ForgotPassword/ForgotPassword';
import ForgotPasswordConfirmation from '@/pages/ForgotPasswordConfirmation/ForgotPasswordConfirmation';
import ResetPassword from '@/pages/ResetPassword/ResetPassword';
import ResetPasswordConfirmation from '@/pages/ResetPasswordConfirmation/ResetPasswordConfirmation';
import Account from '@/pages/Profile/ProfileEdit';
import DeleteAccount from '@/pages/DeleteAccount/DeleteAccount';
import DeleteAccountConfirmation from '@/pages/DeleteAccountConfirmation/DeleteAccountConfirmation';
import DeleteAccountConfirmed from '@/pages/DeleteAccountConfirmed/DeleteAccountConfirmed';
import Lots from '@/pages/Lots/Lots';
import LotBidAndInfo from '@/pages/LotBidAndInfo/LotBidAndInfo';
import LotDetailsRedirect from '@/pages/LotDetailsRedirect/LotDetailsRedirect'
import MyActivity from './pages/MyActivity/MyActvitiy';
import Auctions from '@/pages/Auctions/Auctions';
import HowItWorks from '@/pages/HowItWorks/HowItWorks';
import MobileNotAvailable from '@/pages/MobileNotAvailable/MobileNotAvailable';
import BiddingProfileList from '@/pages/BiddingProfile/BiddingProfileList';
import IndividualProfileAddEdit from '@/pages/BiddingProfile/IndividualProfileAddEdit';
import CompanyProfileAddEdit from '@/pages/BiddingProfile/CompanyProfileAddEdit';
import Catalog from '@/pages/Catalog/Catalog';
import { hideProgressSpinner } from '@/lib/utils/ProgressSpinnerService';
import PrivacyPolicy from '@/pages/PrivacyPolicy/PrivacyPolicy';
import { useFetchServerDateTimeDifference } from "prembid-shared-library-npm/networking";
import ns from "@/lib/utils/NotificationService";
import { DialogContentText } from '@mui/material';
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import { clearOnReceiveMessage, onReceiveMessage } from 'prembid-shared-library-npm/signalr';
import { increaseUnreadNotificationCount } from './components/NotificationPanel/store/NotificationPanel';
import { MessageSubject } from 'prembid-shared-library-npm/types';
import { BlankLayout } from './layouts/BlankLayout';
import { MainLayout } from './layouts/MainLayout';
import { toggleWishlist } from './pages/Lots/store/Lots';
import { handleLotWishlistDataUpsert, handleLotWishlistItemStatusCheck, handleSetWishlistBiddingNotification } from './pages/MyActivity/store/MyActivity';
import { handleCheckAutoLogin, handleClearAutoWishlistData } from './components/LoginForm/store/LoginForm';

const blankLayoutRoutes = {
    '/registerConfirmation': <RegisterConfirmation />,
    '/confirmAccount/:userId/:tokenId': <ConfirmAccount />,
    '/confirmAccountConfirmation': <ConfirmAccountConfirmation />,
    '/mobileNotAvailable': <MobileNotAvailable />,
    '/confirmEmail/:userId/:tokenId': <EmailChangeConfirmation />,
    '/forgotPassword': <ForgotPassword />,
    '/forgotPasswordConfirmation': <ForgotPasswordConfirmation />,
    '/resetPassword/:userId/:tokenId': <ResetPassword />,
    '/resetPasswordConfirmation': <ResetPasswordConfirmation />,
    '/catalog/:auctionId': <Catalog />,
    '/deleteAccount': <DeleteAccount />,
    '/deleteAccountConfirmation/:userId/:tokenId': <DeleteAccountConfirmation />,
    '/deleteAccountConfirmed': <DeleteAccountConfirmed />,
    '/privacypolicy': <PrivacyPolicy />,
}

const unAuthorisedMainLayoutRoutes = {
    '/home': <Home />,
    '/auctions': <Auctions />,
    '/lots/:auctionId': <Lots />,
    '/LotBidAndInfo/:lotId': <LotDetailsRedirect />,
    '/LotBidAndInfo/:auctionId/:lotId': <LotBidAndInfo />,
    '/howitworks': <HowItWorks />,
}

const authorisedMainLayoutRoutes = {
    '/myActivity': <MyActivity />,
    '/account': <Account />,
    '/biddingProfileList': <BiddingProfileList />,
    '/companyProfileAdd': <CompanyProfileAddEdit />,
    '/companyProfileEdit': <CompanyProfileAddEdit />,
    '/individualProfileAdd': <IndividualProfileAddEdit />,
    '/individualProfileEdit': <IndividualProfileAddEdit />
}

interface Props {
    handleConfigVersionFetch_d: () => void,
    handleConfigsFetch_d: (version: any) => void,
    handleCheckAutoLogin_d: (onCompletedCallback: (result: any) => void) => void,
    handleLotWishlistDataUpsert_d: (myWishlistData: any, onCompletedCallback?: (res: any) => void) => void,
    handleToggleWishList_d: (lotId: string) => void,
    handleClearAutoWishlistData_d: () => void,
    handleLotWishlistItemStatusCheck_d: (profileId: any, lotId: any, onCompletedCallback: (result: any) => void) => void,
    handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => void,
    handleHide?: () => void,
    configVersionRetrieved: boolean,
    configsRetrieved: boolean,
    settings: any,
    configVersion: any,
    autoWishlistData: any,
    wishlistNotificationFlagObject: any,
}

//This must stay as new Date() to get the local time. Do not change
const clientTimestamp: number = new Date().getTime();

const RoutesComponent: React.FC<Props> = props => {
    useEffect(() => {
        const callbackId = onReceiveMessage((msg: string) => {
            if (msg) {
                var payload = JSON.parse(msg);
                let loggedInProfileId = PrembidLocalStorage.currentProfile;
                const { lotId = undefined, message = undefined, lotBiddingLink = undefined, profileId = undefined } = payload;

                if (loggedInProfileId === profileId) {
                    switch (payload?.messageSubject) {
                        case MessageSubject.LotBidExtension:
                        case MessageSubject.WonLot:
                        case MessageSubject.MaximumBidAmountReached:
                        case MessageSubject.Outbid:
                        case MessageSubject.RegisteredProfileApproved:
                        case MessageSubject.RegisteredProfileDeclined:
                        case MessageSubject.AuctionStarted:
                        case MessageSubject.PreAuctionEnds:
                        case MessageSubject.PreAuctionStarts:
                        case MessageSubject.AuctionDatesUpdated:
                        case MessageSubject.RegisteredProfileNotRegistered:
                        case MessageSubject.RequestMissingDocuments:

                            if (lotBiddingLink && window.location.pathname !== lotBiddingLink) {
                                ns.custom('', message, [{
                                    buttonText: 'View', onClick: (key) => {
                                        window.location.pathname = lotBiddingLink
                                    }
                                }], 'info')
                            }
                            else {
                                ns.information('', message);
                            }

                            dispatch(increaseUnreadNotificationCount(lotId ? true : false))
                            break;
                    }
                }
            }
        });
        return () => clearOnReceiveMessage(callbackId);
    }, []);

    const {
        handleConfigVersionFetch_d,
        handleConfigsFetch_d,
        handleCheckAutoLogin_d,
        handleLotWishlistDataUpsert_d,
        handleToggleWishList_d,
        handleClearAutoWishlistData_d,
        handleLotWishlistItemStatusCheck_d,
        handleSetWishlistBiddingNotification_d,
        handleHide,
        configVersionRetrieved,
        configsRetrieved,
        settings,
        configVersion,
        autoWishlistData,
        wishlistNotificationFlagObject,
    } = props;

    let modalOpened: boolean = false;
    let retryValidator: number = 0;

    const dispatch = useDispatch();

    const { refetch: refreshServerTimeOffset } = useFetchServerDateTimeDifference({ pathParams: { localDateTimeTicks: clientTimestamp } },
        {
            enabled: false,
            onSuccess(res) {
                //This must stay as new Date() to get the local time. Do not change
                const nowTimeStamp: number = new Date().getTime();

                const diff: number = res?.diff;
                const roundTrip = Math.abs(nowTimeStamp - clientTimestamp);
                const estimatedRequestTime = roundTrip / 2;

                const finalOffset = diff - estimatedRequestTime;

                dispatch(serverDateTimeDifferenceFetchSuccess(Math.abs(finalOffset) <= 400 ? 0 : finalOffset));
                handleConfigVersionFetch_d();
            },
            onError(error) {

                //TODO: We need a new Error Page that we redirect to incase this fails. Failure could be due to internet, server etc
                dispatch(serverDateTimeDifferenceFetchError(error?.message))
            }
        }
    );

    const serverTimeOffsetTimerTickInterval = () => {
        let oldTime = new Date().getTime();
        let isWorkerInitialized = false;

        const newWorker = new Worker('/worker.js');
        newWorker.onmessage = function (e) {
            const time = new Date().getTime();

            //console.log('Message Received From Worker')
            //console.log(Math.abs(time - oldTime))

            if (Math.abs(time - oldTime) > 20000 && isWorkerInitialized) {
                if (retryValidator > 2) {
                    retryValidator = 0;

                    if (!modalOpened) {
                        modalOpened = true;
                        ns.showDialog({
                            title: "Sync Your Clock for Optimal Experience",
                            content: (
                                <>
                                    <DialogContentText className="mt-3 mb-3">To ensure the best user experience, we've noticed your device's time is out of sync with our server.</DialogContentText>

                                    <DialogContentText className="mb-3">Please click the 'Sync' button to resync your clock and maintain seamless access to all features. Please note this will refresh your browser.</DialogContentText>

                                    <DialogContentText>Thank you for helping us enhance your experience! </DialogContentText>
                                </>
                            ),
                            locales: {
                                submitText: "Sync",
                            },
                            onSubmit: () => { window.location.reload(); },
                            allowCloseOnBackDropClick: false
                        });
                    }
                } else {
                    retryValidator++;
                }
            } else {
                isWorkerInitialized = true;
                oldTime = new Date().getTime();
            }
        };

        newWorker.postMessage({ action: 'start', interval: 1000 });

        return () => {
            newWorker.postMessage({ action: 'stop' });
            newWorker.terminate();
        };
    }

    //This post is used to get the difference between the client and server time and thereafter used to set the timers in the app
    useEffect(() => {
        refreshServerTimeOffset();
    }, []);

    useEffect(() => {
        if (configVersionRetrieved && configsRetrieved) {
            serverTimeOffsetTimerTickInterval();
            handleCheckAutoLogin_d((res) => {
                if (res.success && res.response != null) {
                    if (autoWishlistData.lotId && PrembidLocalStorage.currentProfile !== '') {
                        if (handleHide) handleHide();
                        const data = {
                            lotId: autoWishlistData?.lotId,
                            profileId: PrembidLocalStorage.currentProfile,
                        }
                        handleLotWishlistItemStatusCheck_d(PrembidLocalStorage.currentProfile, autoWishlistData?.lotId, function (res) {
                            if (res.response === false) {
                                handleLotWishlistDataUpsert_d(data, function (res) {
                                    if (res.success) {
                                        handleToggleWishList_d(autoWishlistData?.lotId);
                                        let payload = { ...wishlistNotificationFlagObject }
                                        payload.wishlistedActivity = true;
                                        handleSetWishlistBiddingNotification_d(payload);
                                    }
                                    else {
                                        ns.error("", "Failed to wishlist item, please try again.");
                                    }
                                    handleClearAutoWishlistData_d();
                                })
                            } else {
                                handleClearAutoWishlistData_d();
                            }
                        })
                    }
                }
                hideProgressSpinner();
            });
        }
    }, [configVersionRetrieved, configsRetrieved]);

    useEffect(() => {
        if (configVersionRetrieved) {
            handleConfigsFetch_d(configVersion);
        }
    }, [configVersionRetrieved]);

    useEffect(() => {
        if (configVersionRetrieved && configsRetrieved) {
            if (document?.getElementById("favicon") && settings?.VendorName && settings?.VendorFaviconUrl) {
                document.title = settings.VendorName

                var link = document.querySelector("link[id~='favicon']");
                if (!link) {
                    link = document.createElement('link');
                    link.id = 'favicon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                (link as HTMLAnchorElement).href = settings.VendorFaviconUrl;
            }
        }
    }, [configVersionRetrieved, configsRetrieved, settings?.VendorFaviconUrl, settings?.VendorName]);

    useEffect(() => {
        if (settings?.Styles) {
            Object.keys(settings.Styles).forEach(x => {
                if (x.toLowerCase() === 'fontfilename') {
                    var newStyle = document.createElement('style');
                    newStyle.appendChild(document.createTextNode("\
                        @font-face {\
                            font-family: customFont;\
                            src: url('/api/Vendor/RetrieveFont/" + settings.VendorName + "/" + settings.Styles[x] + "');\
                        }\
                    "));

                    document.head.appendChild(newStyle);
                } else {
                    document.documentElement.style.setProperty(`--${x.toLowerCase()}`, settings.Styles[x]);
                }
            })
        }
    }, [settings]);

    return (<>
        {
            configVersionRetrieved && configsRetrieved && <div className="bg-white">
                <Routes>
                    <Route key={"homeRoute"} path="/" element={<Navigate to="/home" />} />
                    <Route key={"blankLayoutRoute"} element={<BlankLayout />}>
                        {Object.keys(blankLayoutRoutes).map(path => (<Route key={path + "Route"} path={path} element={blankLayoutRoutes[path]} />))}
                    </Route>
                    <Route key={"mainLayoutRoute"} element={<MainLayout />} >
                        {Object.keys(unAuthorisedMainLayoutRoutes).map(path => (<Route key={path + "Route"} path={path} element={unAuthorisedMainLayoutRoutes[path]} />))}

                        <Route key={"authRoute"} element={<AuthRoute />}>
                            {Object.keys(authorisedMainLayoutRoutes).map((path) => (
                                <Route key={path + "Route"} path={path} element={authorisedMainLayoutRoutes[path]} />
                            ))}
                        </Route>
                    </Route>
                    <Route key={"baseRoute"} path="/*" element={<Navigate to="/" />} />
                </Routes>
            </div>}
    </>);
}

const mapStateToProps = (state: any) => ({
    configsRetrieved: state.settings.configsRetrieved,
    configVersionRetrieved: state.settings.configVersionRetrieved,
    settings: state.settings.settings,
    configVersion: state.settings.configVersion,
    autoWishlistData: state.loginForm.autoWishlistData,
    wishlistNotificationFlagObject: state.myActivity.wishlistNotificationFlagObject
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleConfigVersionFetch_d: () => dispatch(handleConfigVersionFetch()),
        handleConfigsFetch_d: (version: any) => dispatch(handleConfigsFetch(version)),
        handleCheckAutoLogin_d: (onCompletedCallback: (result: any) => void) => dispatch(handleCheckAutoLogin(onCompletedCallback)),
        handleLotWishlistItemStatusCheck_d: (profileId: any, lotId: any, onCompletedCallback: (result: any) => void) => dispatch(handleLotWishlistItemStatusCheck(profileId, lotId, onCompletedCallback)),
        handleLotWishlistDataUpsert_d: (myWishlistData: any, onCompletedCallback?: (res: any) => void) => dispatch(handleLotWishlistDataUpsert(myWishlistData, onCompletedCallback)),
        handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => dispatch(handleSetWishlistBiddingNotification(payload, onCompletedCallback)),
        handleToggleWishList_d: (lotId: string) => dispatch(toggleWishlist(lotId)),
        handleClearAutoWishlistData_d: () => dispatch(handleClearAutoWishlistData()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesComponent);