export const apiPaths = {
    account: {
        login: "/api/login",
        logout: "/api/logout",
        checkDevice: "/api/checkDevice",
        refreshProfiles: "/api/RefreshProfiles",
        register: "/api/register",
        confirmAccount: "/api/confirmAccount",
        registerConfirmation: "/api/registerConfirmation",
        confirmAccountConfirmation: "/api/confirmAccountConfirmation",
        confirmEmailChange: '/api/confirmEmailChange',
        sendResetPasswordLink: "/api/sendResetPasswordLink",
        forgotPasswordConfirmation: "/api/forgotPasswordConfirmation",
        resetPassword: "/api/resetPassword",
        resetPasswordConfirmation: "/api/resetPasswordConfirmation",
        retrieveAccount: "/api/User/Retrieve",
        updateAccount: "/api/User/Update",
        termsandconditions: "/api/termsandconditions",
        upsertUserDevice: "/api/upsertUserDevice",
        upsertTermsAcceptance: "/api/upsertTermsAcceptance",
        deleteAccount: "/api/deleteAccount",
        deleteAccountConfirmation: "/api/deleteAccountConfirmation",
        removeAccountDeletion: "/api/removeAccountDeletion",
    },

    auction: {
        getById: "/api/Auction/Retrieve",
        retrieveAuctionPageData: "/api/Auction/RetrieveAuctionPageData",
        retrieveHomePageData: '/api/Auction/RetrieveHomePageData',
    },

    lots: {
        retrieveLotPageData: "/api/Lot/RetrieveLotPageData",
        retrieveLotDetailsPageData: "/api/Lot/RetrieveLotDetailsPageData",
        sendEnquiry: "/api/Lot/SendEnquiry",
        RetrieveAllLotTypes: "/api/Lot/RetrieveAllLotTypes",
        RetrieveAllLotTypesLookups: "/api/Lot/RetrieveAllLotTypeLookups/:auctionId",
        RetrieveFilteredLotPageData: "/api/Lot/RetrieveFilteredLotPageData",
        DetermineAuctionForLot: "/api/Lot/DetermineAuctionForLot/:lotId"
    },

    lotWishlist: {
        retrieveLotWishlist:"/api/LotWishlist/RetrieveLotWishlist",
        retrieveAllLotWishlist: "/api/LotWishlist/RetrieveAllLotWishlist",
        retrieveAllMyBiddingActivity: "/api/LotWishlist/RetrieveAllMyBiddingActivity",
        upsertLotWishlist: "/api/LotWishlist/UpsertLotWishlist",
        lotWishlistItemStatusCheck: "/api/LotWishlist/LotWishlistItemStatusCheck",
        createLotWishlist:"/api/LotWishlist/CreateLotWishlist",
        deleteLotWishlist: "/api/LotWishlist/DeleteLotWishlist",
    },

    document: {
        createDocument: "/api/Document/CreateDocument",
        deleteDocument: "/api/Document/DeleteDocument",
        retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecord: "/api/Document/RetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecord",
        retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPublicAccess: "/api/Document/RetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPublicAccess",
        retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPrivateAccess: "/api/Document/RetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPrivateAccess",
        retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByApprovedProfile: "/api/Document/RetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByApprovedProfile",
        retrieveTsAndCsForDocumentType: "/api/Document/RetrieveTsAndCsForDocumentType",
        retrieveRegistrationProcessDocuments: "/api/Document/RetrieveRegistrationProcessDocuments",
        retrieveRegistrationDocuments: "/api/Document/RetrieveRegistrationDocuments/:auctionId",
        uploadRegistrationProcessDocuments: "/api/Document/UploadRegistrationProcessDocuments",
        retrieveProfileDocuments: "/api/Document/retrieveProfileDocuments/:profileId/:profileType",
        downloadSecureFile: "/api/Document/DownloadSecureFile/:url",
    },

    bids: {
        create: "/api/Bid/Create",
        createMaximumBid: "/api/Bid/CreateMaximumBid",
        retrieveBiddingPageData: "/api/Bid/RetrieveBiddingPageData",
        subscribeToBidUpdates: "/api/Bid/SubscribeToBidUpdates",
        unsubscribeToBidUpdates: "/api/Bid/UnsubscribeToBidUpdates"
    },
    vendor: {
        retrieveWhiteLabelling: "/api/Vendor/RetrieveWhiteLabelling",
        retrieveConfigVersion: "/api/Vendor/RetrieveConfigVersion",
        retrieveConfigByVersion: "/api/Vendor/RetrieveConfigByVersion",
        retrieveBankDetailsByAuctionId: "/api/Vendor/retrieveBankDetailsByAuctionId/:auctionId",
        retrievePrivacyPolicy: "/api/Vendor/RetrievePrivacyPolicy",
        retrieveServerDateTimeDifference: "/api/Vendor/RetrieveServerDateTimeDifference/:localDateTimeTicks",
    },
    profile: {
        validate: "/api/Profiles/ValidateRegisteredProfile",
        create: "/api/Profiles/RegisteredProfileCreate",
        retrieveRegistrationProcessStatus: "/api/Profiles/RetrieveRegistrationProcessStatus",
        retrieveBiddingProfiles: "/api/Profile/RetrieveAllBiddingProfilesByIdentityUserId",
        retrieveCompanyBiddingProfile: "/api/Profiles/RetrieveCompanyBiddingProfile",
        retrieveIndividualBiddingProfile: "/api/Profiles/RetrieveIndividualBiddingProfile",
        individualBiddingProfileCreate: "/api/Profiles/IndividualBiddingProfileCreate",
        individualBiddingProfileUpdate: "/api/Profiles/IndividualBiddingProfileUpdate",
        companyBiddingProfileCreate: "/api/Profiles/CompanyBiddingProfileCreate",
        companyBiddingProfileUpdate: "/api/Profiles/CompanyBiddingProfileUpdate",
        IndividualBiddingProfileDelete: "/api/Profiles/IndividualBiddingProfileDelete",
        CompanyBiddingProfileDelete: "/api/Profiles/CompanyBiddingProfileDelete",
        submitAuctionRegistrationWizardData: "/api/Profiles/SubmitAuctionRegistrationWizardData",
        deleteAllBiddingProfiles: "/api/Profiles/DeleteAllBiddingProfiles/:userId",
        updateProfileAddress: "/api/Profiles/UpdateAddress",
        finishRegistration: "/api/Profiles/FinishRegistration",
        setProfileAsDefault: "/api/Profiles/SetProfileAsDefault",
    },
    notification: {
        getNotificationsByProfileId: "/api/Notification/RetrieveNotifications",
        getLotNotificationsByProfileId: "/api/Notification/RetrieveLotNotifications",
        getNotificationCountByProfileId: "/api/Notification/RetrieveNotificationCount",
        setNotificationAsRead: "/api/Notification/SetNotificationAsRead",
        setAllNotificationsAsRead: "/api/Notification/SetAllNotificationsAsRead",
        deleteNotification: "/api/Notification/DeleteNotification"
    },
    contact: {
        retrieveContact: "/api/Contact/RetrieveContact/:contactId",
        retrieveContactImage: "/api/Image/RetrieveContactImage/:imageId",
    }
};
