import { PrembidLocalStorage } from '@/lib/utils/PrembidLocalStorage';
import { Box, Button, Card, Checkbox, CircularProgress, Container, Divider, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { connect, } from 'react-redux';
import { clearMyActivityWishlistStatus, handleRetrieveAllLotWishlist, handleRetrieveAllMyBiddingActivity, clearApiData } from './store/MyActivity';
import { clearResponses } from '../Lots/store/Lots';
import LotDetailCard from '@/components/Cards/LotDetailCard';
import { handleAuctionByIdFetch } from '../Auctions/store/Auctions';
import ns from '@/lib/utils/NotificationService';
import useHistory from '../../lib/hooks/useHistory';
import useStyles from '../MyActivity/MyActivityStyles';
import { isArray } from 'lodash';
import { useSignalRConnectionStatus } from 'prembid-shared-library-npm/signalr';
import { HeaderBlock } from '../../components/composite/HeaderBlock';
import { Pagination } from '../../components/composite/Pagination';
import { FaSearch, FaUndo } from 'react-icons/fa';
import StickyBox from "react-sticky-box";
import { changeProfileId } from '../../components/LoginForm/store/LoginForm';
import { CompactLotCard } from '../../components/library/CompactLotCard';
import { ViewModeToggle, View } from '../../components/library/ViewModeToggle';

interface Props {
    handleLotWishlistDataFetch_d: (profileId: string, filters: any, pagination?: any, onCompletedCallback?: (res: any) => void) => void,
    handleMyBiddingActivityDataFetch_d: (profileId: string, filters: any, pagination?: any, onCompletedCallback?: (res: any) => void) => void,
    handleAuctionByIdFetch_d: (auctionId: string, onCompletedCallback?: (res: any) => void) => void,
    clearResponses_d: () => void,
    clearApiData_d: () => void,
    loading: boolean,
    settings: any,
    contact?: any,
    profileId: any,
    error: any,
    errorText: string,
    lotWishlistData: any,
    myBiddingActivityData: any,
    lotWishlistPagination?: any,
    myBiddingActivityPagination?: any,
    onHandleChangePage?: (pagination?: any) => void,
    availableFilters?: any[],
    selectedFilters?: any,
    onFiltersChange?: (filters: any) => void,
    isMyActivityWishlistObject: any,
    clearMyActivityWishlistStatus_d: () => void
}

const MyActivity: React.FC<Props> = props => {
    const {
        handleLotWishlistDataFetch_d,
        handleMyBiddingActivityDataFetch_d,
        clearApiData_d,
        settings,
        isMyActivityWishlistObject,
        clearMyActivityWishlistStatus_d,
    } = props;

    const history = useHistory();
    const { classes } = useStyles();

    const { classes: { compactLotCardsContainer, containerWidth } } = useStyles()
    let sortBy: string[] = ["Number: Ascending", "Number: Descending", "Opening Bid: Low to High", "Opening Bid: High to Low", "Time: Remaining"];
    let lotType: string[] = ["Open", "Completed"];
    let pageType: string[] = ["My Current Activity", "My Wishlist"];
    let viewType: View[] = [View.DETAIL, View.COMPACT]

    const initialFilters = {
        lotType: "Open",
        sortBy: sortBy[sortBy.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")],
        pageType: "My Current Activity",
        viewType: viewType[viewType.indexOf(settings.DefaultLotView ?? View.COMPACT)],
    };

    const [currentProfileId, setCurrentProfileId] = useState<any>(PrembidLocalStorage.currentProfile);

    const [activityData, setActivityData] = useState<any>(null);
    const [wishlistData, setWishlistData] = useState<any>(null);

    const [filterFields, setFilterFields] = useState<any>(initialFilters);
    const [selectedTab, setSelectedTab] = useState<number | undefined>(0);

    const [lastTop, setLastTop] = useState<any>(0);
    const [searchbarOffset, setSearchbarOffset] = useState<number>(140);
    const [searchFiltersOffset, setSearchFiltersOffset] = useState<number>(240);
    const [gridLoading, setGridLoading] = React.useState<boolean>(false);

    useEffect(() => {
        const urlSearchParams: any = new URLSearchParams(window.location.search);
        const params: any = Object.fromEntries(urlSearchParams.entries());
        const { type = undefined, filter = undefined, sortBy = undefined, top = undefined, ...tempParams } = params;

        if (selectedTab === undefined) {
            switch (type) {
                case 'wishlist': setSelectedTab(1); break;
                case 'activity':
                default: setSelectedTab(0); break;
            }
            if (filter) setFilterFields({ ...filterFields, lotType: filter });
            if (sortBy) setFilterFields({ ...filterFields, sortBy: sortBy });
            if (top) setLastTop(top);
        }
        else if (selectedTab === 0) {
            refreshMyActivity(filterFields, activityData?.pagination ?? tempParams);
        }
        else if (selectedTab === 1) {
            refreshMyWishlist(filterFields, wishlistData?.pagination ?? tempParams);
        }
    }, [selectedTab, currentProfileId, filterFields]);

    useEffect(() => {
        if (activityData?.pagination) {
            const { total, ...rest } = activityData?.pagination;

            window.scroll({ top: lastTop, left: 0, behavior: 'auto' })
            setLastTop(undefined)

            let path = `/myActivity?` + new URLSearchParams({ ...rest, type: 'activity', filter: filterFields?.lotType, sortBy: filterFields?.sortBy }).toString();
            history.replace(path);
        }
    }, [activityData?.pagination])

    useEffect(() => {
        if (wishlistData?.pagination) {
            const { total, ...rest } = wishlistData?.pagination;

            window.scroll({ top: lastTop, left: 0, behavior: 'auto' })
            setLastTop(undefined)

            let path = `/myActivity?` + new URLSearchParams({ ...rest, type: 'wishlist', filter: filterFields?.lotType, sortBy: filterFields?.sortBy }).toString();
            history.replace(path);
        }
    }, [wishlistData?.pagination])

    useEffect(() => {
        if (PrembidLocalStorage.currentProfile !== currentProfileId) {
            setCurrentProfileId(PrembidLocalStorage.currentProfile);
        }
    }, [PrembidLocalStorage.currentProfile]);

    const refreshMyActivity = (filters?: any, pagination?: any) => {
        setGridLoading(true);
        clearApiData_d();

        const formattedFilters = {
            ...filters,
            lotType: filters?.lotType.toLowerCase(), // Convert to lowercase
        };

        handleMyBiddingActivityDataFetch_d(PrembidLocalStorage.currentProfile, formattedFilters, pagination, (res) => {
            if (res.success) {
                setActivityData(null);
                setActivityData(res.response);
            } else {
                ns.error('', res.error);
            }

            setGridLoading(false);
        });
    }

    const refreshMyWishlist = (filters?: any, pagination?: any) => {
        setGridLoading(true);
        clearApiData_d();

        const formattedFilters = {
            ...filters,
            lotType: filters?.lotType.toLowerCase() === "open/upcoming" ? "open" : filters?.lotType.toLowerCase(),
        };

        handleLotWishlistDataFetch_d(PrembidLocalStorage.currentProfile, formattedFilters, pagination, (res) => {
            if (res.success) {
                setWishlistData(null);
                var data = res.response;
                const newList = data.list.map((x: any) => {
                    x.lots.isOnWishlist = true;
                    return x;
                });
                setWishlistData({ ...data, list: newList });
            } else {
                ns.error('', res.error);
            }

            setGridLoading(false);
        });
    }

    useEffect(() => {
        if (isMyActivityWishlistObject?.lotId !== "") {
            if (selectedTab === 0) {
                const index = activityData?.list?.findIndex((e: any) => e.id === isMyActivityWishlistObject?.lotId);
                if (index > -1) {
                    let temp = { ...activityData }
                    let tempObject = temp.list[index];
                    tempObject.isOnWishlist = !(tempObject?.isOnWishlist);
                    temp.list[index] = tempObject;
                    setActivityData(temp);
                }
            }

            if (selectedTab === 1) {
                const index = wishlistData?.list?.findIndex((e: any) => e.lots?.id === isMyActivityWishlistObject?.lotId);
                if (index > -1) {
                    let temp = { ...wishlistData }
                    let tempObject = temp.list[index].lots;
                    if (tempObject.isOnWishlist !== undefined) {
                        tempObject.isOnWishlist = !(tempObject?.isOnWishlist);
                    } else {
                        tempObject.isOnWishlist = false
                    }

                    temp.list[index].lots = tempObject;
                    setWishlistData(temp);
                }
            }

            clearMyActivityWishlistStatus_d();
        }

    }, [isMyActivityWishlistObject])

    function getLotFeatures(lot: any) {
        let featuresArr: any[] = [];

        if (lot?.dataCaptured?.additionalProperties) {
            lot.dataCaptured.additionalProperties.map(x => {
                if (x.name !== '' && x.value !== '' && !x.hidden)
                    featuresArr.push({ ...x, key: x.name })
            });

            featuresArr.sort((a, b) => a.order - b.order);
        }

        return featuresArr;
    }

    const handleWishlistPageChange = (pagination: any) => {
        refreshMyWishlist(filterFields, pagination)
    }

    const handleMyActivityPageChange = (pagination: any) => {
        refreshMyActivity(filterFields, pagination)
    }

    const handleSelectedFilterClick = (e: any) => {
        setFilterFields(prev => ({
            ...prev,
            lotType: e.target.value,
        }));
    };

    const handleSelectedPageClick = (e: any) => {
        let index = pageType.findIndex((item) => item === e.target.value);
        const selectedPage = pageType[index];

        setFilterFields(prev => {
            // Determine the valid options for the new tab
            const validLotTypes = selectedPage === "My Wishlist"
                ? ["Open/Upcoming", "Completed"]
                : ["Open", "Completed"];

            // Preserve the selected lotType if it's valid, otherwise default to the first valid option
            const newLotType = validLotTypes.includes(prev.lotType) ? prev.lotType : validLotTypes[0];

            return {
                ...prev,
                pageType: selectedPage,
                lotType: newLotType, // Keep the selected `lotType` if valid
            };
        });

        setSelectedTab(index);
    };

    const handleSortByValueChanged = (e: any) => {
        let index = sortBy.findIndex((item) => item === e.target.value)
        setFilterFields({ ...filterFields, sortBy: sortBy[index] });
    }

    const handleSetSelectedView: React.Dispatch<SetStateAction<View>> = (value: SetStateAction<View>) => {
        setFilterFields(prev => ({
            ...prev,
            viewType: typeof value === "function" ? value(prev.viewType) : value
        }));
    };

    const handleApplyClicked = () => {
        if (selectedTab === 0) {
            refreshMyActivity(filterFields, activityData?.pagination);
        }
        else if (selectedTab === 1) {
            refreshMyWishlist(filterFields, wishlistData?.pagination);
        }
    }

    useSignalRConnectionStatus({
        onConnect: () => {
            const urlSearchParams: any = new URLSearchParams(window.location.search);
            const params: any = Object.fromEntries(urlSearchParams.entries());
            const { type = undefined, filter = undefined, sortBy = undefined, top = undefined, ...tempParams } = params;
            if (selectedTab === 0) {
                refreshMyActivity(filterFields, activityData?.pagination ?? tempParams);
            } else if (selectedTab === 1) {
                refreshMyWishlist(filterFields, wishlistData?.pagination ?? tempParams);
            }
        },
    });

    return <div className="pb-5" style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
        <HeaderBlock imageSrc={settings.VendorBannerUrl} />

        <StickyBox offsetTop={searchbarOffset} style={{ zIndex: 23 }} >
            <div className="py-2" style={{ background: "#2b2b2b" }}>
                <Container maxWidth={false} className={containerWidth}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}>
                        {
                            settings && <>
                                <Grid item md={3} xs={1} />
                                <Grid item md={3} xs={5}>
                                    <Select
                                        margin='dense'
                                        value={filterFields?.pageType}
                                        onChange={handleSelectedPageClick}
                                        fullWidth
                                        defaultValue={pageType[pageType.indexOf("My Current Activity")]}
                                        variant="outlined"
                                        style={{ background: '#ffffff' }} >
                                        {
                                            pageType?.map((value) => (
                                                <MenuItem
                                                    key={value}
                                                    value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>

                                <Grid item md={3} xs={5}>
                                    <Select
                                        margin="dense"
                                        value={filterFields?.lotType}
                                        onChange={handleSelectedFilterClick}
                                        fullWidth
                                        variant="outlined"
                                        defaultValue={lotType[lotType.indexOf("Open")]}
                                        style={{ background: '#ffffff' }}>
                                        {
                                            (filterFields?.pageType === "My Wishlist" ? ["Open/Upcoming", "Completed"] : ["Open", "Completed"])
                                                .map((value) => (
                                                    <MenuItem key={value} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </Grid>

                                <Grid item md={3} xs={5}>
                                    <Select
                                        margin='dense'
                                        value={filterFields?.orderBy}
                                        onChange={handleSortByValueChanged}
                                        fullWidth
                                        defaultValue={sortBy[sortBy.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")]}
                                        variant="outlined"
                                        style={{ background: '#ffffff' }} >
                                        {
                                            sortBy?.map((value) => (
                                                <MenuItem
                                                    key={value}
                                                    value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Container>
            </div>
        </StickyBox>

        <div className="pt-5 pb-5">
            <Container maxWidth={false} className={containerWidth}>
                <Grid
                    container
                    justifyContent="flex-start"
                    spacing={2}>
                    <Grid item md={3}>
                        <StickyBox offsetTop={searchFiltersOffset}>
                            <Card className="p-1" raised={true}>
                                <Grid
                                    container
                                    spacing={0}>
                                    <Grid item md={2}>
                                        <ViewModeToggle
                                            selectedView={filterFields?.viewType}
                                            setSelectedView={handleSetSelectedView}
                                        />
                                    </Grid>

                                    <Grid item md={10} className="mt-2">
                                        <Pagination
                                            onHandleChangePage={handleMyActivityPageChange}
                                            pagination={activityData?.pagination}
                                            loading={gridLoading}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    filterFields?.sortBy === "Time: Remaining" &&
                                    <Button style={{ background: '#2b2b2b' }} onClick={handleApplyClicked} variant="contained" className="btn-primary p-3 mt-3 ml-3 mb-3 text-white text-capitalize">
                                        Reorder {settings.LotName}{activityData?.list?.length > 1 ? "s" : ""}
                                    </Button>
                                }
                            </Card>
                        </StickyBox>
                    </Grid>

                    <Grid item md={9}>
                        <div>
                            {gridLoading && (
                                <div style={{ position: 'absolute', top: '20%', left: 'calc(50% - 50px)', zIndex: 1 }}>
                                    <CircularProgress
                                        className="text-center"
                                        style={{
                                            margin: "auto",
                                            color: settings?.Styles?.OverrideLoadingSpinnerColor
                                                ? settings?.Styles?.OverrideLoadingSpinnerColor
                                                : settings?.Styles?.Primary,
                                        }}
                                        size={80}
                                    />
                                </div>
                            )}

                            {/* Display 'No Lots' message if the selected dataset is empty */}
                            {(
                                filterFields?.pageType === "My Wishlist"
                                    ? (wishlistData?.pagination?.total || 0) === 0
                                    : (activityData?.list?.length || 0) === 0
                            ) && (
                                    <div className="text-center mt-5" style={{ opacity: gridLoading ? 0.5 : 1 }}>
                                        <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>
                                            {"No " + settings.LotName + "s to be displayed"}
                                        </h4>
                                    </div>
                                )}

                            {(filterFields?.pageType === "My Wishlist" ? wishlistData?.list : activityData?.list) && (
                                <>
                                    {filterFields?.viewType === View.COMPACT && (
                                        <Box style={{ flexGrow: 1, opacity: gridLoading ? 0.5 : 1 }} className={compactLotCardsContainer}>
                                            {(filterFields?.pageType === "My Wishlist" ? wishlistData?.list : activityData?.list)?.map((item: any) => {
                                                const lot = filterFields?.pageType === "My Wishlist" ? item.lots : item; // Extract correct lot data

                                                return (
                                                    <CompactLotCard
                                                        key={lot.id}
                                                        lot={lot}
                                                        startDateTimeAt={lot?.startDateTimeAt}
                                                        hasExtensions={lot?.lotBidExtensionCount > 0}
                                                        commission={lot?.commission > 0 ? lot?.commission?.toFixed(2) : 0}
                                                        lotFeatures={getLotFeatures(lot)}
                                                        auctionType={lot?.auctionType}
                                                        overrideGuidePriceRulesAndAlwaysHide={false}
                                                        registrationStatus={() => { }}
                                                        overrideShowBidButtonForCompletedLots={false}
                                                        auctionId={lot?.auctionId}
                                                        wishListStatus={lot?.isOnWishlist ?? false}
                                                        variant={""}
                                                        highestBid={lot?.winningRegisteredProfileAmount}
                                                        highestBidProfileId={lot?.winningProfileId}
                                                        settings={settings}
                                                        extendedBy={lot?.extendedBy}
                                                    />
                                                );
                                            })}
                                        </Box>
                                    )}

                                    {filterFields?.viewType === View.DETAIL && (
                                        <Grid container spacing={3} style={{ flexGrow: 1, opacity: gridLoading ? 0.5 : 1 }}>
                                            {(filterFields?.pageType === "My Wishlist" ? wishlistData?.list : activityData?.list)?.map((item: any) => {
                                                const lot = filterFields?.pageType === "My Wishlist" ? item.lots : item; // Extract correct lot data

                                                return (
                                                    <LotDetailCard
                                                        key={lot?.id}
                                                        imgSrc={lot?.imageUrl}
                                                        displayFullLengthImage={true}
                                                        header={lot?.name}
                                                        description={lot?.description}
                                                        auctionId={lot?.auctionId}
                                                        auctionType={lot?.auctionType}
                                                        lotId={lot?.id}
                                                        lotNumber={lot?.number}
                                                        startDateTimeAt={lot?.startDateTimeAt}
                                                        endDateTimeAt={lot?.endDateTimeAt}
                                                        successBadgeText={lot?.stcStatus?.toString()}
                                                        startingPrice={lot?.startingPrice}
                                                        guidePrice={lot?.guidePrice?.toString()}
                                                        dangerBadgeText={lot?.number}
                                                        lotFeatures={getLotFeatures(lot)}
                                                        hasExtensions={lot?.lotBidExtensionCount > 0}
                                                        isActive={lot?.isActive}
                                                        commission={lot?.commission > 0 ? lot?.commission?.toFixed(2) : 0}
                                                        registrationStatus={() => { }}
                                                        documentCount={(PrembidLocalStorage?.currentUser && PrembidLocalStorage?.currentProfile) ? (lot?.publicDocumentCount + lot?.privateDocumentCount) : lot?.publicDocumentCount}
                                                        wishListStatus={lot?.isOnWishlist}
                                                        overrideGuidePriceRulesAndAlwaysHide={true}
                                                        overrideShowBidButtonForCompletedLots={filterFields?.lotType === "open" ? false : true}
                                                        showReserve={lot?.showReserve}
                                                        highestBid={lot?.winningRegisteredProfileAmount}
                                                        highestBidProfileId={lot?.winningProfileId}
                                                        extendedBy={lot?.extendedBy}
                                                        lot={lot}
                                                    />
                                                );
                                            })}
                                        </Grid>
                                    )}
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </div>
}

const mapStateToProps = (state: any) => ({
    lotWishlistData: state.myActivity.lotWishlistData,
    myBiddingActivityData: state.myActivity.myBiddingActivityData,
    lotWishlistPagination: state.myActivity.lotWishlistPagination,
    myBiddingActivityPagination: state.myActivity.myBiddingActivityPagination,
    error: state.myActivity.error,
    errorText: state.myActivity.errorText,
    success: state.myActivity.success,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    registrationStatus: state.participateButton.registrationStatus,
    profileId: state.loginForm.changeProfileId,
    isMyActivityWishlistObject: state.myActivity.isMyActivityWishlistObject,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleLotWishlistDataFetch_d: (profileId: string, filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => dispatch(handleRetrieveAllLotWishlist(profileId, filters, pagination, onCompletedCallback)),
        handleMyBiddingActivityDataFetch_d: (profileId: string, filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => dispatch(handleRetrieveAllMyBiddingActivity(profileId, filters, pagination, onCompletedCallback)),
        handleAuctionByIdFetch_d: (auctionId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleAuctionByIdFetch(auctionId, onCompletedCallback)),
        clearResponses_d: () => dispatch(clearResponses()),
        clearApiData_d: () => dispatch(clearApiData()),
        clearMyActivityWishlistStatus_d: () => dispatch(clearMyActivityWishlistStatus()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyActivity);